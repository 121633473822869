import DefaultApiService from '@/services/defaultApi.service'

const ENDPOINT = 'exportJson'

const VIEW_ADMIN = 'admin'
const VIEW_SUPPORT = 'support'
const VIEW_ADVISOR = 'advisor'

class ExportService {
  constructor (view) {
    this.view = view
  }

  list (params = {}) {
    params.view = this.view
    params.type = 'table'
    return DefaultApiService.list(ENDPOINT, params)
  }

  demo (params = {}) {
    params.view = this.view
    params.type = 'demo'
    return DefaultApiService.list(ENDPOINT, params)
  }

  mails (params = {}) {
    params.view = this.view
    params.type = 'mails'
    return DefaultApiService.list(ENDPOINT, params)
  }

  infos (params = {}) {
    params.view = this.view
    params.type = 'infos'
    return DefaultApiService.list(ENDPOINT, params)
  }

  missingInfos (params = {}) {
    params.view = this.view
    params.type = 'missingInfos'
    return DefaultApiService.list(ENDPOINT, params)
  }

  expiry (params = {}) {
    params.view = this.view
    params.type = 'expiry'
    return DefaultApiService.list(ENDPOINT, params)
  }
}

export default ExportService
export const exportServiceForAdminView = new ExportService(VIEW_ADMIN)
export const exportServiceForSupportView = new ExportService(VIEW_SUPPORT)
export const exportServiceForAdvisorView = new ExportService(VIEW_ADVISOR)
